import React from 'react'
import { colors } from '../styles/variables'
import { media } from '../styles/minWidth'

const Price = ({ title, price }) => (
  <div
    css={{
      color: colors.brown,
      display: 'flex',
      fontFamily: 'Quicksand, sans-serif',
      justifyContent: 'space-between',
      lineHeight: '1.5em',
      '&:not(:last-child)': { marginBottom: 10 },
      '@media(min-width: 360px)': { fontSize: '1.2em' },
      '@media(min-width: 500px)': { fontSize: '1.625em' },
    }}
  >
    <span css={{ marginRight: 20 }}>{title}</span>
    <span>{price} euro</span>
  </div>
)

export default () => (
  <div
    css={{
      marginBottom: 30,
      [media.medium]: {
        marginBottom: 60,
      },
    }}
  >
    <Price title="Intake consult, 90 - 120 minuten" price="150" />
    <Price title="Vervolgconsult, per 30 minuten" price="37,50" />
    <Price title="Telefonisch consult, 15 minuten" price="18,75" />
  </div>
)
